<template>
	<div>
		<el-dialog title="选择学生" :visible.sync="isShowModal" :close-on-click-modal="false" :destroy-on-close="true"
			@close="closeDialog" custom-class="cus_dialog">
			<div style="display:flex;height:100%">
				<div style="width:60%;height:100%;overflow: auto;">


					<el-tree ref="stuTree" :data="treeData" :props="firstCascaderProps" node-key="value"
						:default-expand-all="true" :expand-on-click-node="false" @node-click="checkDept">
						<div class="custom-tree-node flexBetween" slot-scope="{ node, data }" style="width: 100%;">
							<div>
								<span class="nodetag" style="background-color:rgba(0,0,0,0);color:#ccc"
									v-if="node.data.type == 'sxxz'">
									◯
								</span>
								<span :style="{ backgroundColor: node.data.biye == 1 ? '#ccc' : '' }">{{
								node.data.name || node.data.stu_name
								}}</span>
								<!-- <span class="nodetag" style="background-color:chocolate"
									v-if="node.data.type == 'xx'">校级</span>
								<span class="nodetag" style="background-color:crimson"
									v-if="node.data.type == 'yx'">院系</span>
								<span class="nodetag" style="background-color:cornflowerblue"
									v-if="node.data.type == 'zy'">专业</span>
								<span class="nodetag" style="background-color:yellowgreen"
									v-if="node.data.type == 'bm'">部门</span>
								<span class="nodetag" style="background-color:aqua"
									v-if="node.data.type == 'nj'">年级</span>
								<span class="nodetag" style="background-color:blue"
									v-if="node.data.type == 'jc'">届次</span>
								<span class="nodetag" style="background-color:plum"
									v-if="node.data.type == 'bj'">班</span> -->
								<span class="nodetag" style="background-color:purple"
									v-if="node.data.type == 'sxxz'">组</span>
								<span class="tagtch" v-for="(t,i) in node.data.teachers" :key="i">
									{{t.user_type=='zd'?'指导老师：':''}}{{t.user_type=='bzr'?'班主任：':''}}{{t.username}}
								</span>
							</div>




						</div>
					</el-tree>

				</div>
				<div style="width:40%;color:orangered">
					<div>
						提示：请勿跨届次分配实习小组!
					</div>
					<div>
						只能挂靠到实习小组，若无实习小组，请至【基础数据-组织架构】菜单新增实习小组!
					</div>
				</div>
			</div>

			<!-- <el-row style="text-align:right">
				<el-button type="primary" size="small" @click="setCkdStudents">
					选择完成
				</el-button>
			</el-row> -->



		</el-dialog>
	</div>
</template>

<script>


export default {
	props: ["users"],
	data() {
		return {
			isShowModal: true,
			treeData: [],
			firstCascaderProps: {
				label: "name",
				children: "children",
			},

		};
	},
	mounted() {
		this.getTree()
	},
	methods: {
		getTree() {
			this.$http.post("/api/sys_office_tree", { not_type: "'bj'", biye: 0 }).then(res => {

				this.treeData = res.data.tree


			})
		},
		setCkdStudents() {
			let ckdstus = this.$refs["stuTree"].getCheckedKeys(true)
			let arr = [], arrDic = {};
			for (let sid of ckdstus) {
				let nid = sid.replace("bj_", "").replace("sxxz_", "")
				if (!arrDic[nid]) {
					arr.push(nid)
				}

			}
			this.$$parent(this, "getUsers", arr.join(","))

		},
		closeDialog() {
			this.$$parent(this, "DeptUserDialog", false)
		},
		checkDept(a) {
			if (a.type == "sxxz") {
				this.$confirm('确认将学生分组至【' + a.name + '】?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.closeDialog()
					this.$$parent(this, "saveGroupUser", a.id)
				})

			}

		}
	}
}
</script>
<style scoped>
.nodetag {
	display: inline-block;
	padding: 0 5px;
	font-size: 12px;
	transform: scale(0.8, 0.8);
	background-color: #409eff;
	color: #fff;
	border-radius: 4px;
}

.tagtch {
	display: inline-block;
	padding: 0 5px;
	font-size: 12px;
	transform: scale(0.8, 0.8);
	background-color: #fff;
	color: #409eff;
	border-radius: 4px;
	border: 1px solid #409eff;
	box-sizing: border-box;
}
</style>