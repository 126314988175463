<template>
  <div>
    <el-dialog class="cus_dialog" title="学生详细信息" width="1000px" :visible.sync="visible" :close-on-click-modal="false"
      @close="closeDialog" custom-class="cus_dialog">

      <div class="dialog_right">
        <div class="cellGroupBarTitle flexStart" style="margin-top:10px;">
          <span></span>
          <span style="font-weight: 400">基本信息</span>
        </div>

        <div class="flexStart"
          style="flex-wrap: wrap;width: 100%;margin-top: 10px;border-right: 1px solid #d9e4f6;border-bottom: 1px solid #d9e4f6;">
          <div v-if="nDictArr && nDictArr.length > 0 && isAdmin" class="lineRow flexStretch" style="width:100%;">
            <div class="cell flexStart">实习去向</div>
            <div class="cell flexBetween flex_1">

              <el-select size="" v-model="newsxqx">
                <el-option label="未实习" :value="0"></el-option>
                <el-option :label="item.param_desc" :value="item.param_value" v-for="(item, index) in nDictArr"
                  :key="index"></el-option>
              </el-select>
              <div class="ml10">
                <el-popconfirm v-if="!formData.locked" width="250" confirmButtonText="确定" cancelButtonText="取消"
                  icon="el-icon-info" iconColor="#1289ff" @confirm="saveQX" placement="right" title="是否确定修改学生实习去向？">
                  <el-button slot="reference" size="mini" type="primary" plain>保存</el-button>
                </el-popconfirm>

              </div>
            </div>
          </div>
          <div v-if="formData.photo" class="lineRow flexStretch" style="width:100%;">
            <div class="cell flexStart" style="min-height: 40px;height: unset;">一寸照片</div>
            <div class="cell flexStart flex_1" style="height: unset;min-height: 40px;flex-wrap:wrap;">
              <div class="flexCenter"
                style="position:relative;height:100px;width:100px;border:1px dashed #c0ccda;border-radius:6px;">
                <el-image style="height:100%" :src="formData.photo" fit="cover"
                  :preview-src-list="[formData.photo]"></el-image>
              </div>
            </div>
          </div>

          <div class="lineRow flexStretch" style="width:100%;">
            <div class="cell flexStart required">所在班级</div>
            <div class="cell flexStart flex_1">
              <el-cascader size="" ref="firstCascader" :options="treeData" :props="defaultProps"
                v-model="formData.class_id" :show-all-levels="false" placeholder="请选择" filterable
                style="width:100%;font-size:16px;"></el-cascader>

            </div>
          </div>
          <div class="lineRow flexStretch" style="width:50%;">
            <div class="cell flexStart required">学生姓名</div>
            <div class="cell flexStart flex_1">
              <el-input size="" class="input-with-select" style="width:100%;font-size:16px;" v-model="formData.stu_name"
                placeholder="请输入姓名"></el-input>

            </div>
          </div>

          <div class="lineRow flexStretch" style="width:50%;">
            <div class="cell flexStart required">手机号</div>
            <div class="cell flexStart flex_1">
              <el-input size="" class="input-with-select" style="width:100%;font-size:16px;" v-model="formData.phone"
                placeholder="请输入手机号"></el-input>

            </div>
          </div>

          <div class="lineRow flexStretch" style="width:50%;">
            <div class="cell flexStart required">学号</div>
            <div class="cell flexStart flex_1">
              <el-input class="elInput" style="width:100%" v-model="formData.stu_code" placeholder="请输入学号"></el-input>

            </div>
          </div>
          <div class="lineRow flexStretch" style="width:50%;">
            <div class="cell flexStart">学籍号</div>
            <div class="cell flexStart flex_1">
              <el-input class="elInput" style="width:100%" v-model="formData.xuejihao" placeholder="请输入学籍号"></el-input>

            </div>
          </div>



          <div class="lineRow flexStretch" style="width:50%;">
            <div class="cell flexStart required">证件类型</div>
            <div class="cell flexStart flex_1">
              <el-select v-model="formData.idcard_type" @change="changeidcard_type">
                <el-option label="身份证" :value="0"></el-option>
                <el-option label="港澳台/护照" :value="1"></el-option>
              </el-select>
            </div>
          </div>
          <div class="lineRow flexStretch" style="width:50%;">
            <div class="cell flexStart required">证件号码</div>
            <div class="cell flexStart flex_1">
              <el-input class="elInput" style="width:100%"
                :readonly="formData.identity_card && formData.identity_card.indexOf('***') > 0 ? true : false"
                v-model="formData.identity_card" placeholder="请输入证件号码" @input="getBirthdayFromIdCard"></el-input>
              <div class="ml10">
                <span v-if="formData.id" @click="showIdcardForm"
                  style="line-height:40px;padding-left:10px;cursor:pointer" title="修改身份证号">
                  <i class="el-icon-edit"></i>
                </span>
              </div>
            </div>
          </div>
          <div class="lineRow flexStretch" style="width:50%;">
            <div class="cell flexStart">性别</div>
            <div class="cell flexStart flex_1">
              <div style="position:relative">
                <el-radio-group size="small" v-model="formData.gender" :disabled="formData.idcard_type == 0">
                  <el-radio :label="1">男</el-radio>
                  <el-radio :label="2">女</el-radio>
                </el-radio-group>
                <div v-if="formData.idcard_type === 0"
                  style="position:absolute;top: 0; left: 0; width: 100%; height: 100%;"></div>
              </div>
            </div>
          </div>
          <div class="lineRow flexStretch" style="width:50%;">
            <div class="cell flexStart">生日</div>
            <div class="cell flexStart flex_1">
              <el-date-picker :readonly="formData.idcard_type == 0" value-format="yyyy-MM-dd"
                v-model="formData.birthday" style="width: 100%" type="date" placeholder="选择日期"></el-date-picker>

            </div>
          </div>
          <div class="lineRow flexStretch" style="width:50%;">
            <div class="cell flexStart">民族</div>
            <div class="cell flexStart flex_1">
              <el-select v-model="formData.minzu">
                <el-option v-for="(item, idx) in minzuList" :key="idx" :label="item.param_desc"
                  :value="item.param_desc"></el-option>
              </el-select>
            </div>
          </div>
          <div class="lineRow flexStretch" style="width:50%;">
            <div class="cell flexStart">政治面貌</div>
            <div class="cell flexStart flex_1">
              <el-select v-model="formData.zzmm">
                <el-option v-for="(item, idx) in zzmmList" :key="idx" :label="item.param_desc"
                  :value="item.param_desc"></el-option>
              </el-select>
            </div>
          </div>
          <div class="lineRow flexStretch" style="width:50%;">
            <div class="cell flexStart required">入学日期</div>
            <div class="cell flexStart flex_1">
              <el-date-picker value-format="yyyy-MM-dd" v-model="formData.ctime" style="width: 100%" type="date"
                placeholder="选择日期"></el-date-picker>
            </div>
          </div>
          <div class="lineRow flexStretch" style="width:50%;">
            <div class="cell flexStart">毕业日期</div>
            <div class="cell flexStart flex_1">
              <el-date-picker value-format="yyyy-MM-dd" v-model="formData.graduate" style="width: 100%" type="date"
                placeholder="选择日期"></el-date-picker>
            </div>
          </div>

          <div class="lineRow flexStretch" style="width:50%;">
            <div class="cell flexStart">外语种类</div>
            <div class="cell flexStart flex_1">
              <el-select v-model="formData.efl_type">
                <el-option v-for="(item, idx) in LANGUAGE" :key="idx" :label="item.param_desc"
                  :value="item.param_desc"></el-option>
              </el-select>
            </div>
          </div>
          <div class="lineRow flexStretch" style="width:50%;">
            <div class="cell flexStart">外语熟练程度</div>
            <div class="cell flexStart flex_1">
              <el-select v-model="formData.efl_proficiency">
                <el-option v-for="(item, idx) in lng_level" :key="idx" :label="item.param_desc"
                  :value="item.param_desc"></el-option>
              </el-select>
            </div>
          </div>
          <div class="lineRow flexStretch" style="width:50%;">
            <div class="cell flexStart">户口所在地</div>
            <div class="cell flexStart flex_1">
              <el-cascader v-model="formData.hkszd" :options="ChinaData" :props="{ emitPath: false }"></el-cascader>

            </div>
          </div>
          <div class="lineRow flexStretch" style="width:50%;">
            <div class="cell flexStart">户口性质</div>
            <div class="cell flexStart flex_1">
              <el-select v-model="formData.hkxz">
                <el-option label="农业户口" value="农业户口"></el-option>
                <el-option label="城镇非农" value="城镇非农"></el-option>
              </el-select>
            </div>
          </div>

          <div class="lineRow flexStretch" style="width:50%;">
            <div class="cell flexStart">籍贯</div>
            <div class="cell flexStart flex_1">
              <el-input class="elInput" style="width:100%" v-model="formData.jiguan" placeholder="请输入籍贯"></el-input>

            </div>
          </div>
          <div class="lineRow flexStretch" style="width:50%;">
            <div class="cell flexStart">家庭邮编</div>
            <div class="cell flexStart flex_1">
              <el-input class="elInput" style="width:100%" v-model="formData.zip_code" placeholder="家庭邮编"></el-input>

            </div>
          </div>
          <div class="lineRow flexStretch" style="width:100%;">
            <div class="cell flexStart">家庭住址</div>
            <div class="cell flexStart flex_1">
              <el-input class="elInput" style="width:100%" v-model="formData.jtzz" placeholder="请输入家庭住址"></el-input>

            </div>
          </div>
          <div class="lineRow flexStretch" style="width:100%;">
            <div class="cell flexStart">所在地区</div>
            <div class="cell flexStart flex_1">
              <el-input class="elInput" disabled style="width:100%" v-model="formData.the_area_name"
                placeholder="所在地区"></el-input>
            </div>
          </div>
          <div class="lineRow flexStretch" style="width:100%;">
            <div class="cell flexStart">所在地住址</div>
            <div class="cell flexStart flex_1">
              <el-input class="elInput" readonly style="width:100%" v-model="formData.xjzdz"
                placeholder="所在地址"></el-input>
            </div>
          </div>

          <div class="lineRow flexStretch" style="width:50%;">
            <div class="cell flexStart">QQ号码</div>
            <div class="cell flexStart flex_1">
              <el-input class="elInput" style="width:100%" v-model="formData.qq" placeholder="QQ号码"></el-input>

            </div>
          </div>
          <div class="lineRow flexStretch" style="width:50%;">
            <div class="cell flexStart">邮箱</div>
            <div class="cell flexStart flex_1">
              <el-input class="elInput" style="width:100%" v-model="formData.dzyx" placeholder="请输入邮箱"></el-input>

            </div>
          </div>

          <div class="lineRow flexStretch" style="width:50%;">
            <div class="cell flexStart">监护人1姓名</div>
            <div class="cell flexStart flex_1">
              <el-input class="elInput" style="width:100%" v-model="formData.jhr1name" placeholder="监护人1姓名"></el-input>

            </div>
          </div>
          <div class="lineRow flexStretch" style="width:50%;">
            <div class="cell flexStart">监护人1电话</div>
            <div class="cell flexStart flex_1">
              <el-input class="elInput" style="width:100%" v-model="formData.jhr1phone" placeholder="监护人1电话"></el-input>

            </div>
          </div>

          <div class="lineRow flexStretch" style="width:50%;">
            <div class="cell flexStart">监护人2姓名</div>
            <div class="cell flexStart flex_1">
              <el-input class="elInput" style="width:100%" v-model="formData.jhr2name" placeholder="监护人2姓名"></el-input>

            </div>
          </div>

          <div class="lineRow flexStretch" style="width:50%;">
            <div class="cell flexStart">监护人2电话</div>
            <div class="cell flexStart flex_1">
              <el-input class="elInput" style="width:100%" v-model="formData.jhr2phone" placeholder="监护人2电话"></el-input>

            </div>
          </div>

          <div class="lineRow flexStretch" style="width:50%;">
            <div class="cell flexStart">身高(CM)</div>
            <div class="cell flexStart flex_1">
              <el-input class="elInput" style="width:100%" v-model="formData.height" placeholder="身高"></el-input>

            </div>
          </div>
          <div class="lineRow flexStretch" style="width:50%;">
            <div class="cell flexStart">体重(KG)</div>
            <div class="cell flexStart flex_1">
              <el-input class="elInput" style="width:100%" v-model="formData.weight" placeholder="体重"></el-input>

            </div>
          </div>
          <div class="lineRow flexStretch" style="width:50%;">
            <div class="cell flexStart">健康状况</div>
            <div class="cell flexStart flex_1">
              <el-select v-model="formData.health">
                <el-option label="健康" value="0"></el-option>
                <el-option label="良好" value="1"></el-option>
                <el-option label="较差" value="2"></el-option>
              </el-select>
            </div>
          </div>
          <div class="lineRow flexStretch" style="width:50%;">
            <div class="cell flexStart required">排序号</div>
            <div class="cell flexStart flex_1">
              <el-input-number :min="1" v-model.trim="formData.sort" placeholder="请输入排序号(数字)"
                controls-position="right" />
            </div>
          </div>
        </div>


        <div class="cellGroupBarTitle flexStart" style="margin-top:10px;">
          <span></span>
          <span style="font-weight: 400">在校表现</span>
        </div>
        <div class="flexStart"
          style="flex-wrap: wrap;width: 100%;margin-top: 10px;border-right: 1px solid #d9e4f6;border-bottom: 1px solid #d9e4f6;">
          <div class="lineRow flexStretch" style="width:100%;">
            <div class="cell flexStart" style="min-height: 40px;height: unset;">专业技能</div>
            <div class="cell flexStart flex_1" style="height: unset;min-height: 40px;flex-wrap:wrap;padding-top:8px;">
              <div style="display:inline-block; margin-right:10px;vertical-align: top; position: relative;"
                v-for="(item, i) in formData.skill_json" :key="i">{{ i + 1 }}、{{ item }}</div>
              <div v-if="!formData.skill_json || formData.skill_json.length == 0">-</div>
            </div>
          </div>

          <div class="lineRow flexStretch" style="width:100%;">
            <div class="cell flexStart" style="min-height: 40px;height: unset;">专业主要课程</div>
            <div class="cell flexStart flex_1" style="height: unset;min-height: 40px;flex-wrap:wrap;padding-top:8px;">
              <div style="display:inline-block; margin-right:10px;vertical-align: top; position: relative;"
                v-for="(item, i) in formData.course" :key="i">{{ i + 1 }}、{{ item }}</div>
              <div v-if="!formData.course || formData.course.length == 0">-</div>
            </div>
          </div>

          <div class="lineRow flexStretch" style="width:100%;">
            <div class="cell flexStart" style="min-height: 40px;height: unset;">专业证书</div>
            <div class="cell flexStart flex_1" style="height: unset;min-height: 40px;flex-wrap:wrap;padding-top:8px;">
              <div style="display:inline-block; margin-right:10px;vertical-align: top; position: relative;"
                v-for="(img, i) in formData.skill_img" :key="i">
                <el-image style="width:100px;height:100px" :src="img.file" fit="cover"
                  :preview-src-list="[img.file]"></el-image>
                <div style="line-height:20px">
                  {{ img.name }}
                </div>
              </div>
              <div v-if="!formData.skill_img || formData.skill_img.length == 0">-</div>

            </div>
          </div>

          <div class="lineRow flexStretch" style="width:100%;">
            <div class="cell flexStart" style="min-height: 40px;height: unset;">特长</div>
            <div class="cell flexStart flex_1" style="height: unset;min-height: 40px;flex-wrap:wrap;padding-top:8px;">
              {{ formData.specialty||'-' }}
            </div>
          </div>

          <div class="lineRow flexStretch" style="width:100%;">
            <div class="cell flexStart" style="min-height: 40px;height: unset;">奖励和处分</div>
            <div class="cell flexStart flex_1" style="height: unset;min-height: 40px;flex-wrap:wrap;padding-top:8px;">
              <div style="display:inline-block; margin-right:10px;vertical-align: top; position: relative;"
                v-for="(img, i) in formData.awards" :key="i"> {{ i + 1 }}、{{ img }}</div>
              <div v-if="!formData.awards || formData.awards.length==0">-</div>
            </div>
          </div>

          <div class="lineRow flexStretch" style="width:100%;">
            <div class="cell flexStart" style="min-height: 40px;height: unset;">校内职务情况</div>
            <div class="cell flexStart flex_1" style="height: unset;min-height: 40px;flex-wrap:wrap;padding-top:8px;">
              <div style="display:inline-block; margin-right:10px;vertical-align: top; position: relative;"
                v-for="(item, i) in formData.duties" :key="i">{{ i + 1 }}、{{ item }}</div>
              <div v-if="!formData.duties || formData.duties.length == 0">-</div>
            </div>

          </div>

          <div class="lineRow flexStretch" style="width:100%;">
            <div class="cell flexStart" style="min-height: 40px;height: unset;">技能大赛奖项</div>
            <div class="cell flexStart flex_1" style="height: unset;min-height: 40px;flex-wrap:wrap;padding-top:8px;">
              <div style="display:inline-block; margin-right:10px;vertical-align: top; position: relative;"
                v-for="(item, i) in formData.skills_competition" :key="i">{{ i + 1 }}、{{ item }}</div>
              <div v-if="!formData.skills_competition || formData.skills_competition.length == 0">-</div>
            </div>

          </div>

          <div class="lineRow flexStretch" style="width:100%;">
            <div class="cell flexStart" style="min-height: 40px;height: unset;">校级奖励</div>
            <div class="cell flexStart flex_1" style="height: unset;min-height: 40px;flex-wrap:wrap;padding-top:8px;">
              <div style="display:inline-block; margin-right:10px;vertical-align: top; position: relative;"
                v-for="(item, i) in formData.school_level" :key="i">{{ i + 1 }}、{{ item }}</div>
              <div v-if="!formData.school_level || formData.school_level.length == 0">-</div>
            </div>
          </div>

          <div class="lineRow flexStretch" style="width:100%;">
            <div class="cell flexStart" style="min-height: 40px;height: unset;">个人小结</div>
            <div class="cell flexStart flex_1" style="height: unset;min-height: 40px;flex-wrap:wrap;padding-top:8px;">
              {{ formData.summary || '-' }}
            </div>
          </div>



        </div>

      </div>
      <el-row>
        <div style="text-align:right;margin:40px 0 10px 0;">
          <span style="color:orangered;font-size:12px">初始密码为手机号后六位</span>
        </div>
      </el-row>

      <el-form v-if="false" :model="formData" :rules="rules" ref="ruleForm" label-width="120px">
        <el-row v-if="nDictArr && nDictArr.length > 0 && isAdmin">



          <el-col :span="8">
            <el-form-item label="实习去向:">
              <el-select size="small" v-model="newsxqx">
                <el-option label="未实习" :value="0"></el-option>
                <el-option :label="item.param_desc" :value="item.param_value" v-for="(item, index) in nDictArr"
                  :key="index"></el-option>
              </el-select>

            </el-form-item>
          </el-col>
          <el-col :span="2">
            <div style="height: 40px;margin-bottom: 22px;" class="flexStart">
              <el-popconfirm v-if="!formData.locked" width="250" confirmButtonText="确定" cancelButtonText="取消"
                icon="el-icon-info" iconColor="#1289ff" @confirm="saveQX" placement="right" title="是否确定修改学生实习去向？">
                <el-button slot="reference" size="small" type="primary" plain>保存</el-button>
              </el-popconfirm>
            </div>
          </el-col>
        </el-row>
        <el-row v-if="formData.photo">
          <el-col :span="8">
            <el-form-item label="一寸照片:">
              <el-image style="width:100px" :src="formData.photo" fit="cover"
                :preview-src-list="[formData.photo]"></el-image>
            </el-form-item>
          </el-col>
        </el-row>



        <el-row>
          <el-col :span="8">
            <el-form-item label="所在班级:" required prop="class_id">
              <el-cascader ref="firstCascader" :options="treeData" :props="defaultProps" v-model="formData.class_id"
                :show-all-levels="false" placeholder="请选择" filterable style="width:100%"></el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="学生姓名:" required prop="stu_name">
              <el-input class="elInput" style="width:100%" v-model="formData.stu_name" placeholder="请输入姓名"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="手机号:" required prop="phone">
              <el-input class="elInput" style="width:100%" v-model="formData.phone" placeholder="请输入手机号"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="学号" required prop="stu_code">
              <el-input class="elInput" style="width:100%" v-model="formData.stu_code" placeholder="请输入学号"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="学籍号:">
              <el-input class="elInput" style="width:100%" v-model="formData.xuejihao" placeholder="请输入学籍号"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="邮箱" prop="dzyx">
              <el-input class="elInput" style="width:100%" v-model="formData.dzyx" placeholder="请输入邮箱"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="证件类型" required prop="idcard_type">
              <el-select v-model="formData.idcard_type" @change="changeidcard_type">
                <el-option label="身份证" :value="0"></el-option>
                <el-option label="港澳台/护照" :value="1"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="证件号码">
              <el-input class="elInput" style="width:100%"
                :readonly="formData.identity_card && formData.identity_card.indexOf('***') > 0 ? true : false"
                v-model="formData.identity_card" placeholder="请输入证件号码" @input="getBirthdayFromIdCard"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="1">
            <span v-if="formData.id" @click="showIdcardForm" style="line-height:40px;padding-left:10px;cursor:pointer"
              title="修改身份证号">
              <i class="el-icon-edit"></i>
            </span>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="性别:" required prop="gender">
              <div style="position:relative">
                <el-radio-group size="small" v-model="formData.gender" :disabled="formData.idcard_type == 0">
                  <el-radio :label="1">男</el-radio>
                  <el-radio :label="2">女</el-radio>
                </el-radio-group>
                <div v-if="formData.idcard_type === 0"
                  style="position:absolute;top: 0; left: 0; width: 100%; height: 100%;"></div>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="生日">
              <el-date-picker :readonly="formData.idcard_type == 0" value-format="yyyy-MM-dd"
                v-model="formData.birthday" style="width: 100%" type="date" placeholder="选择日期"></el-date-picker>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="入学日期" required prop="ctime">
              <el-date-picker value-format="yyyy-MM-dd" v-model="formData.ctime" style="width: 100%" type="date"
                placeholder="选择日期"></el-date-picker>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="民族">
              <el-select v-model="formData.minzu">
                <el-option v-for="(item, idx) in minzuList" :key="idx" :label="item.param_desc"
                  :value="item.param_desc"></el-option>
              </el-select>
            </el-form-item>
          </el-col>


          <el-col :span="8">
            <el-form-item label="政治面貌">
              <el-select v-model="formData.zzmm">
                <el-option v-for="(item, idx) in zzmmList" :key="idx" :label="item.param_desc"
                  :value="item.param_desc"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="外语种类">
              <el-select v-model="formData.efl_type">
                <el-option v-for="(item, idx) in LANGUAGE" :key="idx" :label="item.param_desc"
                  :value="item.param_desc"></el-option>
              </el-select>
            </el-form-item>
          </el-col>

        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="户口所在地">
              <el-cascader v-model="formData.hkszd" :options="ChinaData" :props="{ emitPath: false }"></el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="户口性质">
              <el-select v-model="formData.hkxz">
                <el-option label="农业户口" value="农业户口"></el-option>
                <el-option label="城镇非农" value="城镇非农"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="外语熟练程度">
              <el-select v-model="formData.efl_proficiency">
                <el-option v-for="(item, idx) in lng_level" :key="idx" :label="item.param_desc"
                  :value="item.param_desc"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="籍贯">
              <el-input class="elInput" style="width:100%" v-model="formData.jiguan" placeholder="请输入籍贯"></el-input>

              <!-- <el-cascader v-model="formData.jiguan" :options="ChinaDataNoDist"
                        :props="{ emitPath: false }">
              </el-cascader>-->
            </el-form-item>
          </el-col>
          <el-col :span="16">
            <el-form-item label="家庭住址">
              <el-input class="elInput" style="width:100%" v-model="formData.jtzz" placeholder="请输入家庭住址"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="所在地区">
              <el-input class="elInput" disabled style="width:100%" v-model="formData.the_area_name"
                placeholder="所在地区"></el-input>
              <!-- <el-cascader v-model="formData.the_area_name" :options="ChinaDataNoDist"
                                :props="{ emitPath: false }">
              </el-cascader>-->
            </el-form-item>
          </el-col>
          <el-col :span="16">
            <el-form-item label="所在地住址">
              <el-input class="elInput" readonly style="width:100%" v-model="formData.xjzdz"
                placeholder="所在地址"></el-input>
              <!-- <el-cascader v-model="formData.the_area_name" :options="ChinaDataNoDist"
                          :props="{ emitPath: false }">
              </el-cascader>-->
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="监护人1姓名">
              <el-input class="elInput" style="width:100%" v-model="formData.jhr1name" placeholder="监护人1姓名"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="监护人1电话">
              <el-input class="elInput" style="width:100%" v-model="formData.jhr1phone" placeholder="监护人1电话"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="QQ号码">
              <el-input class="elInput" style="width:100%" v-model="formData.qq" placeholder="QQ号码"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="监护人2姓名">
              <el-input class="elInput" style="width:100%" v-model="formData.jhr2name" placeholder="监护人2姓名"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="监护人2电话">
              <el-input class="elInput" style="width:100%" v-model="formData.jhr2phone" placeholder="监护人2电话"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="家庭邮编">
              <el-input class="elInput" style="width:100%" v-model="formData.zip_code" placeholder="家庭邮编"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="8">
            <el-form-item label="健康状况">
              <el-select v-model="formData.health">
                <el-option label="健康" value="0"></el-option>
                <el-option label="良好" value="1"></el-option>
                <el-option label="较差" value="2"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="身高">
              <el-input class="elInput" style="width:100%" v-model="formData.height" placeholder="身高"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="体重">
              <el-input class="elInput" style="width:100%" v-model="formData.weight" placeholder="体重"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="毕业日期">
              <el-date-picker value-format="yyyy-MM-dd" v-model="formData.graduate" style="width: 100%" type="date"
                placeholder="选择日期"></el-date-picker>
            </el-form-item>
          </el-col>



          <el-col :span="8">
            <el-form-item label="排序号" prop="sort" required>
              <el-input-number :min="1" v-model.trim="formData.sort" placeholder="请输入排序号(数字)"
                controls-position="right" />
            </el-form-item>
          </el-col>
        </el-row>


        <el-divider></el-divider>

        <el-row>
          <el-col :span="10">
            <el-form-item label="专业技能" prop="sort">
              <div style="line-height:20px">
                <div v-for="(item, i) in formData.skill_json" :key="i">{{ i + 1 }}、{{ item }}</div>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="专业主要课程" prop="sort">
              <div style="line-height:20px">
                <div v-for="(item, i) in formData.course" :key="i">{{ i + 1 }}、{{ item }}</div>
              </div>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="22">
            <el-form-item label="专业证书" prop="sort">

              <div v-for="(img, i) in formData.skill_img" :key="i"
                style="width:100px;height:120px;line-height:20px;display: inline-block; vertical-align: top; margin:0 10px 10px 0 ;">
                <el-image style="width:100px;height:100px" :src="img.file" fit="cover"
                  :preview-src-list="[img.file]"></el-image>
                <div style="line-height:20px">
                  {{ img.name }}
                </div>
              </div>

            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="10">
            <el-form-item label="特长" prop="sort">
              {{ formData.specialty }}
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="奖励和处分" prop="sort">

              <div style="line-height: initial;font-size: inherit;" v-for="(img, i) in formData.awards" :key="i">
                {{ img }}
              </div>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="校内职务情况" prop="sort">

              <div style="line-height: initial;font-size: inherit;" v-for="(img, i) in formData.duties" :key="i">
                {{ i + 1 }}、 {{ img }}
              </div>
            </el-form-item>
          </el-col>


          <el-col :span="10">
            <el-form-item label="技能大赛奖项" prop="sort">

              <div style="line-height: initial;font-size: inherit;" v-for="(img, i) in formData.skills_competition"
                :key="i">
                {{ i + 1 }}、 {{ img }}
              </div>
            </el-form-item>
          </el-col>


          <el-col :span="10">
            <el-form-item label="校级奖励" prop="sort">

              <div style="line-height: initial;font-size: inherit;" v-for="(img, i) in formData.school_level" :key="i">
                {{ i + 1 }}、 {{ img }}
              </div>
            </el-form-item>
          </el-col>





        </el-row>

        <el-row>
          <el-col :span="22">
            <el-form-item label="个人小结" prop="sort">
              <div style="line-height: initial;font-size: inherit;">
                {{ formData.summary }}
              </div>

            </el-form-item>
          </el-col>
        </el-row>




        <el-row>
          <div style="text-align:right">
            <span style="color:orangered;font-size:12px">初始密码为手机号后六位</span>
          </div>
        </el-row>
      </el-form>
      <el-row slot="footer">
        <el-input v-if="needsp && formData.sp_status == 1" v-model="sp_word" placeholder="撤销审核时填写"
          style="width:500px;margin-right:20px" size="small"></el-input>
        <el-button v-if="needsp && (formData.sp_status == 1 || formData.sp_status == 2)" size="small" type="warning"
          :loading="loading" title="撤销后学生可再次编辑" @click="cancelForm">{{ formData.sp_status == 1 ? '审核不通过' : '撤销审核'
          }}</el-button>
        <el-button v-if="needsp && formData.sp_status == 1" size="small" type="primary" :loading="loading"
          @click="shenpiForm">审核通过</el-button>
        <el-button size="small" type="default" @click="closeDialog">关闭</el-button>
        <el-button size="small" type="primary" :loading="loading" @click="saveForm">保存</el-button>
      </el-row>
    </el-dialog>
  </div>
</template>
<script>
import china from "../../../utils/arae_value";
const checkphone = (rule, value, callback) => {
  let myreg = /^[1][2,3,4,5,6,7,8,9][0-9]{9}$/;
  if (!value) {
    callback(new Error("请输入手机号"));
  } else if (!myreg.test(value)) {
    callback(new Error("请输入正确的手机号"));
  }
  callback();
};

const checkemail = (rule, value, callback) => {
  let emailPat = /^(.+)@(.+)$/;
  let matchArray = null;
  if (value) {
    matchArray = value.trim().match(emailPat);
  }
  if (value && matchArray == null) {
    callback(new Error("请输入正确的电子邮箱"));
  }
  callback();
};
export default {
  name: "Form",
  props: {
    student_id: {
      type: Number,
      default: 0
    },
    dept_id: {
      type: String,
      default: '0'
    },
    needsp: {
      type: Boolean,
      default: false
    },
    dictArr: {
      type: Array,
      default: e => { return [] }
    }
  },
  data() {
    return {
      visible: true,
      loading: false,
      treeData: [],
      nDictArr: [],
      defaultProps: {
        label: "name",
        value: "id",
        children: "children",
        emitPath: false
      },
      formData: {
        id: 0,
        stu_name: "",
        xuejihao: "",
        stu_code: "",
        identity_card: "",
        idcard_type: 0,
        ctime: "",
        birthday: "",
        gender: "",
        phone: "",
        minzu: "",
        jiguan: "",
        zzmm: "",
        hkszd: "",
        hkxz: "",
        jtzz: "",
        jhr1name: "",
        jhr1phone: "",
        jhr2name: "",
        jhr2phone: "",
        dzyx: "",
        sort: 99
      },
      ChinaData: china,
      ChinaDataNoDist: [],
      minzuList: [],
      zzmmList: [],
      LANGUAGE: [],
      lng_level: [],

      rules: {
        class_id: [
          { required: true, message: "请选择所在班级", trigger: "change" }
        ],
        stu_name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        stu_code: [{ required: true, message: "请输入学号", trigger: "blur" }],
        gender: [
          {
            required: true,
            message: "请先填写身份证号，自动填充性别",
            trigger: "change"
          }
        ],
        phone: [{ validator: checkphone, trigger: "blur", required: true }],
        // identity_card: [
        //   { required: true, message: "请输入证件号", trigger: "blur" }
        // ],
        dzyx: [{ validator: checkemail, trigger: "blur" }],
        ctime: [
          { required: true, message: "请选择入学日期", trigger: "change" }
        ]
      },
      newsxqx:0,
      isAdmin: false,
      sp_word: "",
      isSysAdmin: false,

    };
  },
  mounted() {
    this.$http.post("/api/get_teacher_info").then(res => {
      if (res.data.sys_roles) {
        for (let item of res.data.sys_roles) {
          if (item.role_name == '系统管理员' || item.role_code.indexOf("sysadmin") >= 0) {
            this.isSysAdmin = true
            break
          }
        }
      }
    })
    if (this.dictArr && this.dictArr.length > 0) {
      let len = this.dictArr.length;
      this.nDictArr = JSON.parse(JSON.stringify(this.dictArr))
      for (let i = len - 1; i >= 0; i--) {
        this.nDictArr[i].param_value = parseInt(this.nDictArr[i].param_value);
        if (
          this.nDictArr[i].param_value == 0 ||
          this.nDictArr[i].param_value == 6
        ) {
          this.nDictArr.splice(i, 1);
        }
      }
    }

    this.formData.class_id = this.dept_id;
    this.getUserRoles();
    this.getOrgList();
    this.initNoDist();
    this.getStudentInfo();
    this.getDicList();
  },

  methods: {
    saveQX() {
      console.log(409, this.nDictArr);
      /*if (this.newsxqx == 1) {
        this.$message({
          message: "修改实习去向不能为【实习】，请让学生自行提交实习申请~",
          type: "error",
          duration: 6000
        });
        return;
      }*/
      this.$http
        .post("/api/admin_upd_stu_enter_edit2", {
          stu_id: this.formData.id,
          sxqx: this.newsxqx
        })
        .then(res => {
          this.$message.success("修改实习去向成功~");
          this.getStudentInfo();
          this.$$parent(this, "getStudents");
        });
    },
    getUserRoles() {
      this.$http
        .post("/api/get_teacher_info", { stu_id: this.student_id })
        .then(res => {
          if (res.data && res.data.sys_roles) {
            res.data.sys_roles.map(a => {
              if (a.role_code.indexOf("admin") > -1) {
                this.isAdmin = true;
              }
            });
          }
        });
    },
    getDicList() {
      this.$http
        .post("/api/sys_param_list", {
          param_type: "'POLITICAL_STATUS','NATION','LANGUAGE','lng_level'"
        })
        .then(res => {
          let zzmmList = [],
            minzuList = [],
            LANGUAGE = [],
            lng_level = [];
          for (let item of res.data) {
            if (item.param_type == "POLITICAL_STATUS") {
              zzmmList.push(item);
            }
            if (item.param_type == "NATION") {
              minzuList.push(item);
            }
            if (item.param_type == "LANGUAGE") {
              LANGUAGE.push(item);
            }
            if (item.param_type == "lng_level") {
              lng_level.push(item);
            }
          }
          this.zzmmList = zzmmList;
          this.minzuList = minzuList;
          this.LANGUAGE = LANGUAGE
          this.lng_level = lng_level
        });
    },
    closeDialog() {
      this.$$parent(this, "showTeacherInfo", false);
    },
    initNoDist() {
      let ChinaDataNoDist = JSON.parse(JSON.stringify(china));
      for (let p of ChinaDataNoDist) {
        if (p.children) {
          for (let c of p.children) {
            delete c.children;
          }
        }
      }
      this.ChinaDataNoDist = ChinaDataNoDist;
    },
    getStudentInfo() {
      if (this.student_id) {
        this.$http
          .post("/api/t_sch_students_form", { stu_id: this.student_id })
          .then(res => {
            res.data.identity_card = res.data.idcard_encrypt;
            if (res.data.skill_json) {
              try {
                res.data.skill_json = JSON.parse(res.data.skill_json)
              } catch (error) {
                res.data.skill_json = []
              }
            }
            if (res.data.skill_img) {
              try {
                res.data.skill_img = JSON.parse(res.data.skill_img)
              } catch (error) {
                res.data.skill_img = []
              }
            }
            if (res.data.awards) {
              try {
                res.data.awards = JSON.parse(res.data.awards)
              } catch (error) {
                res.data.awards = []
              }
            }

            if (res.data.course) {
              try {
                res.data.course = JSON.parse(res.data.course)
              } catch (error) {
                res.data.course = []
              }
            }
            if (res.data.duties) {
              try {
                res.data.duties = JSON.parse(res.data.duties)
              } catch (error) {
                res.data.duties = []
              }
            }
            if (res.data.skills_competition) {
              try {
                res.data.skills_competition = JSON.parse(res.data.skills_competition)
              } catch (error) {
                res.data.skills_competition = []
              }
            }
            if (res.data.school_level) {
              try {
                res.data.school_level = JSON.parse(res.data.school_level)
              } catch (error) {
                res.data.school_level = []
              }
            }

            this.formData = res.data;
            this.newsxqx = parseInt(res.data.sxqx);
          });
      }
    },
    getOrgList() {
      this.$http.post("/api/sys_office_tree", { parent_id: 0 }).then(res => {
        this.treeData = res.data.tree;
      });
    },
    changeidcard_type() {
      this.formData.identity_card = "";
      this.formData.birthday = null;
      this.formData.gender = null;
    },
    getBirthdayFromIdCard() {
      let idCard = this.formData.identity_card || "";

      let birthday = "";
      if (idCard != null && idCard != "" && idCard.indexOf("***") < 0) {
        if (idCard.length == 18) {
          birthday = idCard.substr(6, 8);

          birthday = `${birthday.slice(0, 4)}-${birthday.slice(
            4,
            6
          )}-${birthday.slice(6, 8)}`;

          this.formData.birthday = birthday;

          if (parseInt(idCard.substr(16, 1)) % 2 == 1) {
            //男
            this.formData.gender = 1;
          } else {
            //女
            this.formData.gender = 2;
          }
        }
      }
    },
    saveForm() {
      if (!this.isSysAdmin){
      // this.$refs["ruleForm"].validate(valid => {
        if (!this.formData.stu_name.trim()) {
          this.$message.error("请输入姓名");
          return;
        }
        if (!this.formData.stu_code) {
          this.$message.error("请输入学号");
          return;
        }
        if (!this.formData.class_id) {
          this.$message.error("请选择所在班级");
          return;
        }
        if (!this.formData.phone.trim()) {
          this.$message.error("请输入手机号");
          return;
        } else {
          let myreg = /^[1][2,3,4,5,6,7,8,9][0-9]{9}$/;
          if (!myreg.test(this.formData.phone)) {
            this.$message.error("请输入正确的手机号");
            return;
          }
        }
        if (!this.formData.identity_card) {
          this.$message.error("请输入证件号");
          return;
        } else if (this.formData.identity_card.indexOf("***") < 0) {
          let myreg = /^([1-6][1-9]|50)\d{4}(18|19|20)\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
          if (
            this.formData.idcard_type == 0 &&
            this.formData.identity_card.length != 18 &&
            !myreg.test(this.formData.identity_card)
          ) {
            this.$message.error("请输入正确的身份证号");
            return;
          }
        }

        if (!this.formData.ctime) {
          this.$message.error("请输入入学日期");
          return;
        }

        if (this.formData.dzyx && this.formData.dzyx.trim()) {
          var emailPat = /^(.+)@(.+)$/;
          var matchArray = this.formData.dzyx.trim().match(emailPat);
          if (matchArray == null) {
            this.$message.error("请输入正确的电子邮箱");
            return;
          }
        }
      }
        let formData = { ...this.formData }
        if (formData.skill_img) {
          formData.skill_img = JSON.stringify(formData.skill_img)
        }
        if (formData.skill_json) {
          formData.skill_json = JSON.stringify(formData.skill_json)
        }


        if (this.loading) {
          return
        } else {
          this.loading = true
          setTimeout(() => {
            this.loading = false
          }, 3000)
        }
        this.$http.post("/api/t_sch_students_edit", formData).then(res => {
          this.$message.success("保存成功");
          this.$$parent(this, "getStudents");
          this.closeDialog();
        });

      // });
    },
    shenpiForm() {
      this.$refs["ruleForm"].validate(valid => {
        if (!this.formData.stu_name.trim()) {
          this.$message.error("请输入姓名");
          return;
        }
        if (!this.formData.stu_code) {
          this.$message.error("请输入学号");
          return;
        }
        if (!this.formData.class_id) {
          this.$message.error("请选择所在班级");
          return;
        }
        if (!this.formData.phone.trim()) {
          this.$message.error("请输入手机号");
          return;
        } else {
          let myreg = /^[1][2,3,4,5,6,7,8,9][0-9]{9}$/;
          if (!myreg.test(this.formData.phone)) {
            this.$message.error("请输入正确的手机号");
            return;
          }
        }
        if (!this.formData.identity_card) {
          this.$message.error("请输入证件号");
          return;
        } else if (this.formData.identity_card.indexOf("***") < 0) {
          let myreg = /^([1-6][1-9]|50)\d{4}(18|19|20)\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
          if (
            this.formData.idcard_type == 0 &&
            this.formData.identity_card.length != 18 &&
            !myreg.test(this.formData.identity_card)
          ) {
            this.$message.error("请输入正确的身份证号");
            return;
          }
        }

        if (!this.formData.ctime) {
          this.$message.error("请输入入学日期");
          return;
        }

        if (this.formData.dzyx && this.formData.dzyx.trim()) {
          var emailPat = /^(.+)@(.+)$/;
          var matchArray = this.formData.dzyx.trim().match(emailPat);
          if (matchArray == null) {
            this.$message.error("请输入正确的电子邮箱");
            return;
          }
        }
        let formData = { ...this.formData }
        if (formData.skill_img) {
          formData.skill_img = JSON.stringify(formData.skill_img)
        }
        if (formData.skill_json) {
          formData.skill_json = JSON.stringify(formData.skill_json)
        }


        if (this.loading) {
          return
        } else {
          this.loading = true
          setTimeout(() => {
            this.loading = false
          }, 3000)
        }
        formData.sp_status = 2
        formData.sp_word = this.sp_word
        this.$http.post("/api/t_sch_students_edit", formData).then(res => {
          this.$message.success("保存成功");
          this.$$parent(this, "getStudents");
          this.closeDialog();
        });

      });
    },
    cancelForm() {
      let formData = { ...this.formData }
      if (formData.skill_img) {
        formData.skill_img = JSON.stringify(formData.skill_img)
      }
      if (formData.skill_json) {
        formData.skill_json = JSON.stringify(formData.skill_json)
      }

      this.$refs["ruleForm"].validate(valid => {
        formData.sp_status = 3
        formData.sp_word = this.sp_word
        this.$http.post("/api/t_sch_students_edit", formData).then(res => {
          this.$message.success("保存成功");
          this.$$parent(this, "getStudents");
          this.closeDialog();
        });

      });
    },
    showIdcardForm() {
      this.$prompt("请输入身份证号", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /^([1-6][1-9]|50)\d{4}(18|19|20)\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
        inputErrorMessage: "身份证格式不正确"
      }).then(({ value }) => {
        let data = {
          idcard_type: 0,
          id: this.formData.id,
          identity_card: value,
          gender: 0,
          birthday: ""
        };

        let birthday = "";
        if (value) {
          if (value.length == 18) {
            birthday = value.substr(6, 8);

            birthday = `${birthday.slice(0, 4)}-${birthday.slice(
              4,
              6
            )}-${birthday.slice(6, 8)}`;

            data.birthday = birthday;

            if (parseInt(value.substr(16, 1)) % 2 == 1) {
              //男
              data.gender = 1;
            } else {
              //女
              data.gender = 2;
            }
          }
        }

        this.$http.post("/api/upd_student_identity_card", data).then(res => {
          this.$message.success("修改成功");
          this.$$parent(this, "showTeacherInfo", false);
        });
      });
    }
  }
};
</script>

<style scoped lang="less">
/deep/ .el-dialog__body {
  padding: 0px 21px;
  color: #606266;
  font-size: 14px;
  word-break: break-all;
}

/deep/ .el-tabs__item {
  font-size: 18px;
}

.dialog_right {
  .lineRow {
    .cell:first-of-type {
      width: 120px;
      padding-left: 8px;
    }

  }

  .imgaddbtn {
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
    border: 1px dotted #ccc;
    font-size: 30px;
    cursor: pointer;
  }

  .imgremove {
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;

    cursor: pointer;
    position: absolute;
    top: -5px;
    right: -5px;
    color: #ccc;
  }

  .imgremove:hover {
    color: orange;
  }
}

.cusTabTabContainer {
  flex: 1;
  width: 100%;

  ::v-deep .el-tabs {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }

  ::v-deep .el-tabs__content {
    flex: 1;
  }

  ::v-deep .el-tab-pane {
    height: 100%;
  }
}
</style>