const api = {
	//地区
	areaList: '/api/back/sys/area',
	areaDetail: '/api/back/sys/area/form',
	areaSave: '/api/back/sys/area/save',
	areaDel: '/api/back/sys/area/delete',
	//菜单
	menuList: '/api/sys_menu_list',
	menuSave: '/api/sys_menu_edit',
	menuDel: '/api/sys_menu_delete',
	//用户角色(todo ok)
	roleList: '/api/sys_role_list',
	roleSave: '/api/sys_role_edit',
	roleDel: '/api/sys_role_delete',
	getRoleMenu: '/api/get_role_menu',
	setRoleMenu: '/api/set_role_menu',
	//学生机构
	stu_orgList: '/api/sch_major_list',
	stu_orgSave: '/api/sch_major_edit',
	stu_orgDel: '/api/sch_major_delete',
	//教师部门(todo ok)
	tea_deptList: '/api/sys_department_tree',
	tea_deptSave: '/api/sys_department_edit',
	tea_deptDel: '/api/sys_department_delete',
	//部门角色(todo ok)
	deptRoleList: '/api/sys_dept_role_list',
	deptRoleSave: '/api/sys_dept_role_edit',
	deptRoleDel: '/api/sys_dept_role_delete',
	// 部门用户
	deptUserList: '/api/sys_dept_user_list',
	deptUserSave: '/api/sys_dept_user_related_edit',//(todo ok)
	deptUserDel: '/api/sys_dept_user_delete',
	deptRoleRelated: '/api/sys_dept_role_related_edit',//部门角色-部门关联
	//教师
	teacherList: '/api/t_sch_teachers_list',
	teacherSave: '/api/t_sch_teachers_edit',
	teacherDel: '/api/t_sch_teachers_delete',
	//学生
	stuList: '/api/sch_students_list',
	stuSave: '/api/sch_students_edit',
	stuDel: '/api/sch_students_delete',

	divideClass: '/api/t_sch_class_stu_edit',//分班

	//毕业院校
	getSchoolAreaList: '/api/sys_finish_school_area',
	areaSchoolList: '/api/sys_finish_school_list_by_dist',
	areaSchoolSave: '/api/sys_finish_school_edit',
	areaSchoolDel: '/api/sys_finish_school_delete',
	
	/* 模块管理 */
	moduleTree: '/api/sys_module_list',
	moduleEdit: '/api/sys_module_edit',
	moduleDelete: '/api/sys_module_delete',
	moduleTypeEdit: '/api/sys_module_group_edit',
	moduleTypeDelete: '/api/sys_module_group_delete',

}
export default api
