<template>
	<div>
		<el-dialog title="绑定微信" :visible.sync="dialogShow" :close-on-click-modal="false" width="1000px" top="60px"
			@close="closeDialog" custom-class="cus_dialog">
			 <el-table v-tableFit :data="DataList" style="width: 100%;margin-bottom: 20px;" border stripe>
				<el-table-column prop="nickname" label="昵称" width="150px"></el-table-column>
				<el-table-column prop="avatar" label="头像" width="80px">
					<template slot-scope="scope">
						<img :src="scope.row.avatar" style="width: 30px;" v-viewer />
					</template>
				</el-table-column>
				<el-table-column prop="openid" label="OPENID"></el-table-column>
				
				<el-table-column prop="cdate" label="绑定时间"></el-table-column>
				<el-table-column fixed="right" prop="cdate" label="操作" width="80px">
					<template slot-scope="scope">
						<el-popconfirm width="250" confirmButtonText='确定' cancelButtonText='取消' icon="el-icon-info"
							iconColor="red" @confirm="unbindWx(scope.row)" placement="left" title="是否确定解绑？">
							<a slot="reference" size="small"
								style="color: red;font-size: 12px;margin-right: 10px; cursor: pointer;">解绑</a>
						</el-popconfirm>
						
						
						
					</template>
				</el-table-column>

			</el-table>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		props: ["stuid"],
		data() {
			return {
				dialogShow: true,
				DataList: []
			}
		},
		mounted() {
			this.getList()
		},
		methods: {
			getList(){
				this.$http.post("/api/get_mybind_students", {
					stu_id: this.stuid
				}).then(res => {
					this.DataList = res.data
				})
			},
			closeDialog() {
				this.$parent.showBind = false
			},
			unbindWx(row){
				
				this.$http.post("/api/unbind_student", {
					id: row.id
				}).then(res => {
					this.$message.success("解绑成功")
					this.$$parent(this,"getStudents")
					this.$$parent(this,"showBind",false)
				})
			}
		}
	}
</script>

<style>
</style>
